import {NavigateOptions, useNavigate} from "react-router";
import React, {useEffect} from "react";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export class Element {
    name: string;
    path: string;
    identity: boolean;
    element: string | undefined;

    constructor(props: { name: string, path: string, identity?: boolean, element?: string }) {
        this.name = props.name;
        this.path = props.path;
        this.identity = !!props.identity;
        this.element = props.element;
    }

    Redirect() {
        this.Jump({replace: true})
    }

    Jump(options?: NavigateOptions) {
        Navigate?.(this.path, options)
    }

}


export function InitializeNavigate() {
    let navigate = useNavigate()
    useEffect(() => {
        Navigate = navigate
    }, [navigate])
    return <></>
}


const Website = {
    Login: new Element({name: "米莱优选", path: "/login", identity: false, element: "pages/login/login"}),
    Index: new Element({name: "米莱优选", path: "/index", identity: false, element: "pages/index/index"}),
    Identity: new Element({name: "米莱优选", path: "/identity", identity: false, element: "pages/information/information-page"}), //进件
    Results: new Element({name: "米莱优选", path: "/results", identity: false, element: "pages/information/information-results"}), //结果
    Download: new Element({name: "米莱优选", path: "/download", identity: false, element: "pages/download/download"}), //下载页
    Am: new Element({name: "米莱优选", path: "/am", identity: false, element: "pages/am/index"}), //下载页
    Am2: new Element({name: "米莱优选", path: "/am2", identity: false, element: "pages/am/index2"}), //下载页
    Am3: new Element({name: "米莱优选", path: "/am3", identity: false, element: "pages/am/index3"}), //下载页
    ERR403: new Element({name: "503", path: "/503"}),
    ERR404: new Element({name: "404", path: "/404"})
}
let Navigate: NavigateFunction | undefined = undefined;

export {Website, Navigate};


