import {BrowserRouter} from 'react-router-dom';


type constant = {
    Mock: boolean; // 接口基本访问地址
    BaseUrl: string; // 接口基本访问地址
    LoginPage: string; // 登录页地址
    IndexPage: string; // 首页地址
    NODE_ENV: string; // 当前环境
    RouterType: any; // 路由类型
}

const Constant: constant = require("./.env." + process.env.REACT_APP_ENV + ".json");
Constant.RouterType = BrowserRouter;
Constant.NODE_ENV = String(process.env.REACT_APP_ENV);
export default Constant;
