import {Result} from "antd-mobile";

export default function ERR503() {


    return <>      <Result
        status='error'
        title='503'
        description='当前链接不可用'
    /></>
}