import {Navigate, Route, Routes} from 'react-router-dom';
import React, {useMemo} from 'react';
import Constant from '@/config/constant';
import {Element, InitializeNavigate, Website} from "@/config/route";
import ERR404 from "@/config/404";
import Progress from "@/components/nprogress";
import ERR503 from "@/config/503";


export default function RouteApp() {
    const routes = useMemo(() => {
        const router = [];
        const keys = Object.keys(Website);
        for (let i in keys) {
            // @ts-ignore
            let element: Element = Website[keys[i]];
            if (element.element) {
                router.push(<Route key={i} path={element.path}
                                   element={lazy(element.element)}/>);
            }
        }
        router.push(<Route key={"any"} path={'*'} element={<ERR404/>}/>);
        router.push(<Route key={"404"} path={'/404'} element={<ERR404/>}/>);
        router.push(<Route key={"503"} path={'/503'} element={<ERR503/>}/>);
        router.push(<Route key={"/"} path={'/'} element={<Navigate to={"/login"}/>}/>);
        return router;
    }, []);


    return <Constant.RouterType>
        <Routes>
            {routes}
        </Routes>
    </Constant.RouterType>;
}


function lazy(path: string) {
    const Comp = React.lazy(() => import(`@/${path}`));
    return (
        <>
            <React.Suspense fallback={<>   <Progress/></>}>
                <InitializeNavigate/> <Comp/>
            </React.Suspense>
        </>
    );
}
