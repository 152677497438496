import React from 'react';
import './App.less';
import RouteApp from "@/config/route-app";
import 'antd-mobile/es/global'
function App() {
    return (
        <React.Suspense>
            <RouteApp/>
        </React.Suspense>
    );
}

export default App;
